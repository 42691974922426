<template lang="html">
  <div class="recordDetail" v-if="detail">
    <div class="box" id="captureId">
      <div class="head">
        <img src="../../assets/image/logo.png" alt="" />
        <div class="title">商铺缴费凭证</div>
        <div class="monery">￥{{ detail.key_pay_price }}</div>
        <div class="desc">该回单可作为牧予科技有限公司缴费凭证</div>
      </div>
      <img class="segmentation" src="../../assets/image/fenge.png" alt="" />
      <div class="content">
        <div class="list">
          <div class="left">缴费类型</div>
          <div class="right">
            {{ detail.bill_offline_name }}
          </div>
        </div>
        <div class="list">
          <div class="left">支付方式</div>
          <div class="right">
            {{ payType() }}
          </div>
        </div>
        <!--    <div class="list">
          <div class="left">
            商铺号
          </div>
          <div class="right">
            {{detail.merchants_stalls}}
          </div>
        </div> -->
        <div class="list">
          <div class="left">商铺地址</div>
          <div class="right">
            {{ userInfo && userInfo.marketName }}
          </div>
        </div>
        <div class="list">
          <div class="left">收费单位</div>
          <div class="right">
            {{ detail.bill_payee }}
          </div>
        </div>
        <div class="list">
          <div class="left">付款时间</div>
          <div class="right">
            {{ detail.pay_time }}
          </div>
        </div>
        <div class="list">
          <div class="left">订单号</div>
          <div class="right">
            {{ detail.bill_offline_no }}
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="base">
      <div class="btn" @click="downloadVoucher">
        <img src="../../assets/image/download.png" alt="" />
        <span>下载凭证</span>
      </div>
    </div> -->
  </div>
</template>

<script>
import html2canvas from 'html2canvas';

export default {
  name: 'recordVoucher',
  data() {
    return {
      id: '',
      detail: '',
      userInfo: null
    };
  },
  created() {
    this.id = this.$route.query.id;
    this.userInfo = JSON.parse(window.localStorage.getItem('propertyUserInfo'));
    this.getDetail();
  },
  methods: {
    downloadVoucher() {
      const that = this;
      html2canvas(document.querySelector('#captureId')).then((canvas) => {
        let imgUrl = canvas.toDataURL('image/png');

        that.$common.savePicture(imgUrl);
      });
    },
    getDetail() {
      this.$require
        .HttpPost('/h5/payment/getPayHistoryMessage', { bill_offline_id: this.id })
        .then((res) => {
          this.detail = res.data;
        });
    },
    getBillName(key, name) {
      switch (key) {
        case 10:
          return '日常收费';
        case 20:
          return '多项收费';
        case 39:
          return name;
        case 40:
          return '商铺租金';
        default:
          return '默认';
      }
    },
    payType() {
      switch (this.detail.pay_type) {
        case 10:
          return '微信';
        case 20:
          return '支付宝';
        case 30:
          return '现金';
        case 97:
          return '余额';
        case 98:
          return '银行卡';
        case 99:
          return '花呗';
        default:
          return '';
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.recordDetail {
  padding: 0 24px;
  .box {
    border: 5px solid #85c226;
    box-shadow: 0px 0px 25px 0px rgba(57, 57, 57, 0.06);
    border-radius: 30px;
    width: 100%;
    margin-top: 30px;
    .head {
      padding: 50px 40px 20px 40px;
      img {
        width: 120px;
        height: 60px;
      }
      .title {
        text-align: center;
        font-size: 32px;
        color: #000000;
      }
      .monery {
        font-size: 48px;
        font-weight: bold;
        color: #000000;
        text-align: center;
        margin-top: 30px;
      }
      .desc {
        font-size: 28px;
        color: #b9b9b9;
        text-align: center;
        margin-top: 50px;
      }
    }
    .segmentation {
      width: 100%;
      height: 6px;
    }
    .content {
      padding: 40px 20px;
      .list {
        display: flex;
        justify-content: space-between;
        height: 70px;
        align-items: center;
        font-size: 28px;
        color: #000000;
        .right {
          color: #333333;
        }
      }
    }
  }
  .base {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    padding-bottom: 40px;
    .btn {
      width: 250px;
      height: 96px;
      line-height: 96px;
      text-align: center;
      background: #f8f8f8;
      border-radius: 48px;
      font-size: 32px;
      color: #000000;
      opacity: 0.6;
      img {
        width: 32px;
        height: 32px;
        margin-right: 15px;
      }
    }
  }
}
</style>
